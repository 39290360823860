@use "../../global.scss" as *;

.chip {
  $height: 1.8rem;
  display: inline-flex;
  background-color: darken(#74c7ec, 10%);
  box-sizing: border-box;
  height: $height;
  padding: 0.4rem 0.6rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.7rem;
  border-radius: $height / 2;
  letter-spacing: 1px;
  gap: 6px;

  img {
    height: 20px;
  }
}