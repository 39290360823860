._1YzGUa_desktopNav, ._1YzGUa_mobileNav {
  z-index: 999;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  font-family: Inter, sans-serif;
  font-weight: lighter;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

._1YzGUa_desktopNav h1, ._1YzGUa_mobileNav h1 {
  text-align: left;
  color: #2b2a29;
  flex: 1;
  font-size: 24px;
  font-weight: lighter;
}

:is(.dark ._1YzGUa_desktopNav h1, .dark ._1YzGUa_mobileNav h1) {
  color: #fff;
}

._1YzGUa_desktopNav h1 a, ._1YzGUa_mobileNav h1 a {
  color: inherit;
  text-decoration: none;
  transition: color .2s;
}

._1YzGUa_desktopNav h1 a:hover, ._1YzGUa_mobileNav h1 a:hover {
  color: #ccc;
}

._1YzGUa_desktopNav nav, ._1YzGUa_mobileNav nav {
  justify-content: center;
  gap: 20px;
  display: flex;
}

._1YzGUa_mobileNav {
  width: 100vw;
  position: fixed;
}

._1YzGUa_mobileNav h1 {
  text-align: center;
}

._1YzGUa_mobileNav > img {
  width: 30px;
  position: fixed;
  top: 10px;
  right: 10px;
}

.dark ._1YzGUa_mobileNav > img {
  filter: invert();
}

._1YzGUa_mobileNav ._1YzGUa_openMenu {
  z-index: 10;
  backdrop-filter: blur(2px);
  background-color: #0003;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  animation: .2s _1YzGUa_fadeIn;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes _1YzGUa_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

._1YzGUa_mobileNav ._1YzGUa_openMenu ._1YzGUa_menuTray {
  background-color: #faf8f1;
  flex-direction: column;
  gap: 10px;
  width: 200px;
  height: 100%;
  padding: 10px;
  animation: .2s _1YzGUa_slideIn;
  display: flex;
  position: relative;
  box-shadow: 0 0 15px #050515;
}

.dark ._1YzGUa_mobileNav ._1YzGUa_openMenu ._1YzGUa_menuTray {
  background-color: #1e1e2e;
}

@keyframes _1YzGUa_slideIn {
  from {
    transform: translateX(200px);
  }

  to {
    transform: none;
  }
}

._1YzGUa_mobileNav ._1YzGUa_openMenu ._1YzGUa_menuTray a {
  text-decoration: none;
  display: block;
}

._1YzGUa_mobileNav ._1YzGUa_openMenu ._1YzGUa_menuTray a span {
  color: #fff;
  gap: 6px;
  font-size: 20px;
  font-weight: 200;
  display: flex;
}

._1YzGUa_mobileNav ._1YzGUa_openMenu ._1YzGUa_menuTray a span img {
  filter: invert();
  height: 1.3rem;
  display: inline-block;
}

._1YzGUa_glassButton {
  cursor: pointer;
  backdrop-filter: blur(3px);
  width: auto;
  padding: 8px;
  text-decoration: none;
  position: relative;
}

._1YzGUa_glassButton img {
  height: 1.3rem;
  display: inline-block;
}

.dark ._1YzGUa_glassButton img {
  filter: invert();
}

._1YzGUa_glassButton:after {
  z-index: 2;
  content: "";
  mix-blend-mode: difference;
  backdrop-filter: invert();
  background-color: #2b2a29;
  width: 100%;
  height: 1px;
  transition: all .1s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.dark ._1YzGUa_glassButton:after {
  backdrop-filter: none;
  background-color: #fff;
}

._1YzGUa_glassButton:hover:after {
  height: 100%;
}

._1YzGUa_glassButton span {
  color: #000;
  text-align: center;
  align-items: center;
  gap: 6px;
  font-size: 20px;
  font-weight: 200;
  display: flex;
}

.dark ._1YzGUa_glassButton span {
  color: #fff;
}

._1YzGUa_glassButton._1YzGUa_active {
  color: #fff;
  background-color: #000;
}

._1YzGUa_glassButton._1YzGUa_active.dark {
  color: #000;
  background-color: #fff;
}

.wfnC0a_home {
  color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100vw;
  height: 100vh;
  font-family: Inter, sans-serif;
  font-weight: lighter;
  display: flex;
  position: absolute;
}

.dark .wfnC0a_home {
  color: #fff;
}

.wfnC0a_home h1 {
  text-align: center;
  text-shadow: 0 5px 3px #0003;
  font-size: 6rem;
  font-weight: lighter;
}

.dark .wfnC0a_home h1 {
  text-shadow: 0 5px 3px #000;
}

.wfnC0a_home div {
  gap: 10px;
  display: flex;
}

@media only screen and (width <= 800px) {
  .wfnC0a_home {
    gap: 10px;
  }

  .wfnC0a_home h1 {
    font-size: 5rem;
  }
}

.GF-eiG_chip {
  box-sizing: border-box;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #47b5e6;
  border-radius: .9rem;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 1.8rem;
  padding: .4rem .6rem;
  font-size: .7rem;
  display: inline-flex;
}

.GF-eiG_chip img {
  height: 20px;
}

body.dark {
  background-color: #1e1e2e;
}

._63Drnq_readingList {
  color: #2b2a29;
  margin: 100px 10vw;
  font-family: Inter, sans-serif;
}

.dark ._63Drnq_readingList {
  color: #fff;
}

@media (width <= 500px) {
  ._63Drnq_readingList {
    margin: 100px 40px;
  }
}

._63Drnq_readingList h1 {
  margin-bottom: 10px;
  font-size: 4rem;
  font-weight: lighter;
}

._63Drnq_readingList h2 {
  font-size: 2rem;
}

._63Drnq_readingList > p {
  margin: 20px 0;
}

._63Drnq_readingList ._63Drnq_seriesChips {
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
}

@media (width <= 500px) {
  ._63Drnq_readingList ._63Drnq_seriesChips {
    justify-content: center;
  }
}

._63Drnq_readingList ._63Drnq_seriesHeader {
  cursor: pointer;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  padding: 5px 2px;
  display: inline-flex;
}

._63Drnq_readingList ._63Drnq_seriesHeader img {
  width: 20px;
  height: 20px;
}

.dark ._63Drnq_readingList ._63Drnq_seriesHeader img {
  filter: invert();
}

._63Drnq_readingList ._63Drnq_seriesHeader span {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: lighter;
}

._63Drnq_readingList ._63Drnq_books {
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px 16px;
  margin-top: 40px;
  display: grid;
}

@media (width <= 500px) {
  ._63Drnq_readingList ._63Drnq_books {
    grid-template-columns: 1fr;
  }
}

._63Drnq_seriesChip {
  filter: drop-shadow(0 3px 5px #a2a29e99);
  font-weight: bold;
  transition: transform .6s cubic-bezier(.16, 1, .3, 1), box-shadow .6s cubic-bezier(.16, 1, .3, 1), filter .6s cubic-bezier(.16, 1, .3, 1);
}

._63Drnq_seriesChip:hover {
  filter: drop-shadow(0 4px 7px #1117);
  transform: scale(1.03);
}

.dark ._63Drnq_seriesChip, .dark ._63Drnq_seriesChip:hover {
  filter: none;
}

._63Drnq_imageStack {
  cursor: pointer;
  transition: width .4s;
  position: relative;
}

._63Drnq_imageStack img:first-child {
  position: relative;
}

._63Drnq_imageStack img {
  object-fit: cover;
  transition: left .4s, transform .4s;
  position: absolute;
  transform: perspective(1000px)rotateY(-15deg);
}

._63Drnq_bookEntry {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 50px 32px 24px;
  display: flex;
  position: relative;
}

._63Drnq_bookEntry._63Drnq_inProgress {
  background-color: #ede8dd;
  border-radius: 10px;
  grid-template-rows: auto 1fr;
}

.dark ._63Drnq_bookEntry._63Drnq_inProgress {
  background-color: #313244;
}

._63Drnq_bookEntry._63Drnq_inProgress ._63Drnq_inProgressLabel {
  text-transform: uppercase;
  color: #404759;
  letter-spacing: 1px;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 15px;
  left: 20px;
}

.dark ._63Drnq_bookEntry._63Drnq_inProgress ._63Drnq_inProgressLabel {
  color: #99a;
}

@media (width <= 500px) {
  ._63Drnq_bookEntry {
    grid-template-columns: auto;
  }

  ._63Drnq_bookEntry._63Drnq_inProgress {
    grid-template-rows: repeat(3, auto);
  }

  ._63Drnq_bookEntry._63Drnq_inProgress ._63Drnq_inProgressLabel {
    grid-column: unset;
  }

  ._63Drnq_bookEntry ._63Drnq_bookCover {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

._63Drnq_bookEntry ._63Drnq_bookCover img {
  filter: drop-shadow(0 3px 5px #a2a29ebb);
  height: 300px;
  position: relative;
}

@media (width >= 500px) {
  ._63Drnq_bookEntry ._63Drnq_bookCover img {
    cursor: pointer;
    filter: drop-shadow(0 9px 10px #a2a29ebb);
    height: 400px;
    transition: transform .4s cubic-bezier(.37, 0, .63, 1), filter .4s cubic-bezier(.37, 0, .63, 1);
  }

  ._63Drnq_bookEntry ._63Drnq_bookCover img:hover {
    filter: drop-shadow(0 19px 16px #1115);
    transition: transform .9s cubic-bezier(.16, 1, .3, 1), filter .9s cubic-bezier(.16, 1, .3, 1);
    transform: translateY(-5px)scale(1.03);
  }
}

._63Drnq_bookEntry ._63Drnq_bookCover img:before {
  content: "";
  background-color: red;
  width: 300px;
  height: 300px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

._63Drnq_bookEntry ._63Drnq_bookDetails {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

._63Drnq_bookEntry ._63Drnq_bookDetails * {
  text-align: center;
}

._63Drnq_bookEntry ._63Drnq_bookDetails h4 {
  text-transform: uppercase;
  font-weight: 200;
}

._63Drnq_bookEntry ._63Drnq_bookDetails h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

._63Drnq_bookEntry ._63Drnq_bookDetails p {
  margin-bottom: 5px;
}

@media (width <= 500px) {
  ._63Drnq_bookEntry ._63Drnq_bookDetails h3, ._63Drnq_bookEntry ._63Drnq_bookDetails h4, ._63Drnq_bookEntry ._63Drnq_bookDetails p {
    text-align: center;
  }
}

._63Drnq_bookEntry ._63Drnq_bookDetails ._63Drnq_chips {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  display: flex;
}

@media (width <= 500px) {
  ._63Drnq_bookEntry ._63Drnq_bookDetails ._63Drnq_chips {
    justify-content: center;
  }
}

._63Drnq_focusedImage {
  backdrop-filter: blur(2px);
  background-color: #000b;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  animation: .2s _63Drnq_fadeIn;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

._63Drnq_focusedImage img {
  max-height: 90vh;
}

._63Drnq_focusedImage button {
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  top: 75px;
  left: 30px;
}

._63Drnq_focusedImage button img {
  filter: invert();
  width: 30px;
  height: 30px;
  transition: filter .1s;
}

._63Drnq_focusedImage button img:hover {
  filter: invert(70%);
}

.mC_o5q_fadeIn {
  animation: .2s mC_o5q_fadeIn;
}

@keyframes mC_o5q_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mC_o5q_fadeOut {
  animation: .2s mC_o5q_fadeOut;
}

@keyframes mC_o5q_fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.Ct3LvW_darkModeToggle {
  cursor: pointer;
  z-index: 999;
  background: #ccd0da;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  padding: 5px;
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.Ct3LvW_darkModeToggle img {
  object-fit: cover;
  filter: opacity(80%);
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  background: #f8d27baa;
}

body {
  background-color: #faf8f1;
}

body:global(.dark) {
  background-color: #1e1e2e;
}

a {
  color: #cba6f7;
}

a:active {
  color: #9448ef;
}
/*# sourceMappingURL=index.7699b6a7.css.map */
