$font-sans-serif: 'Inter', sans-serif;

//// My theme
$lightThemeText: #2b2a29;
$darkThemeText: white;
$lightThemeBg: #FAF8F1;
$darkThemeBg: #1e1e2e;

//// Darkened Catppuccin Latte Text
// $lightThemeText: hsl(234deg, 16%, 15%);
// $darkThemeText: white;
// $lightThemeBg: #eff1f5;
// $darkThemeBg: #1e1e2e;

$mobileWidth: 500px;

@mixin textBasic {
  font-family: $font-sans-serif;
  color: $lightThemeText;
  font-size: 16px;

  :global(.dark) & {
    color: $darkThemeText;
  }
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::selection {
    background: #f8d27baa;
    // background: #b4befeAA;
  }
}

body {
  // Catppuccin Latte Base
  background-color: $lightThemeBg;

  &:global(.dark) {
    background-color: $darkThemeBg;
  }
}

a {
  color: #cba6f7;

  &:active {
    color: darken(#cba6f7, 20%);
  }
}
